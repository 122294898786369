<template>
  <ui-component-modal
    modalTitle=""
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="save"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth is-striped">
        <tbody>
          <tr>
            <td>Name</td>
            <td><input type="text" v-model="newBundle.Name" /></td>
          </tr>
          <tr>
            <td>Is addon</td>
            <td><input type="checkbox" v-model="newBundle.IsAddOn" /></td>
          </tr>
          <tr>
            <td>Functionalities</td>
            <td>
              <FunctionalityMenu
                @functioniltyUpdate="updateSelectedFunctionalities"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'

export default {
  components: {
    FunctionalityMenu: () =>
      import('@/components/Functionalities/FunctionalityMenu'),
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      newBundle: {
        ChannelId: Number(this.$route.params.channelId),
        Name: '',
        IsAddOn: false,
        Functionalities: [],
      },
    }
  },

  computed: {
    ...mapState('functionalityStore', ['functionalities']),
  },

  created() {
    if (this.functionalities.length === 0) {
      this.getAllFunctionalities()
    }
  },

  methods: {
    ...mapActions('bundleStore', ['createBundle']),
    ...mapActions('functionalityStore', ['getAllFunctionalities']),

    updateSelectedFunctionalities(functionalities) {
      this.newBundle.Functionalities = functionalities
    },

    save() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        self
          .createBundle(self.newBundle)
          .then((response) => {
            if (response.status === 200) {
              // Send message to display toast on screen
              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Bundle created!',
              })

              self.onClickCancel()
            }
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
